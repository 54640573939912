import React, { Component } from "react"
import { TweenLite, Power0 } from "gsap"
import { Global, css } from "@emotion/core"
import SEO from "../../components/seo"
import Header from "../../components/header/header"
import { Link } from "gatsby"
import {
  PortfolioWrapper,
  PortfolioLinks,
  Grid,
} from "../../styled-components/portfolio-styles"
import img1 from "../../images/1.jpg"
import img2 from "../../images/2.jpg"
import img3 from "../../images/3.jpg"
import img4 from "../../images/4.jpg"
import img5 from "../../images/5.jpg"
import img6 from "../../images/6.jpg"
import img7 from "../../images/7.jpg"
import img8 from "../../images/8.jpg"
import img9 from "../../images/9.jpg"

class PortfolioIndex extends Component {
  componentDidMount() {
    if (window.matchMedia("(min-width: 1024px)").matches) {
      Object.assign(document.querySelector("body").style, {
        overflow: "hidden",
      })

      Object.assign(document.querySelector("section").style, {
        height: `${window.innerHeight}px`,
      })
    }

    function tweenItem(item) {
      TweenLite.to(item, 4, {
        x: Math.floor(Math.random() * 50) + 1,
        y: Math.floor(Math.random() * 50) + 1,
        delay: Math.random(),
        ease: Power0.easeNone,
        onComplete: () => {
          tweenItem(item)
        },
      })
    }
    const items = document.querySelectorAll(".grid__item")
    items.forEach(item => {
      tweenItem(item)
    })
  }

  componentWillUnmount() {
    document.querySelector("body").style.overflow = "auto"
    document.querySelector("section").removeAttribute("style")
  }

  render() {
    return (
      <>
        <SEO title="Portfolio" />
        <Header />
        <Global
          styles={css`
            body {
              background-color: #1e1e1e !important;
            }
          `}
        />
        <PortfolioWrapper>
          <PortfolioLinks>
            <Link to="/portfolio/web-design/">Web Design</Link>
          </PortfolioLinks>
          <PortfolioLinks>
            <Link to="/portfolio/videography/">Video</Link>
          </PortfolioLinks>
        </PortfolioWrapper>
        <Grid>
          <img src={img3} alt="Floating clients" className="grid__item" />
          <img src={img4} alt="Floating clients" className="grid__item" />
          <img src={img2} alt="Floating clients" className="grid__item" />
          <img src={img9} alt="Floating clients" className="grid__item" />
          <img src={img1} alt="Floating clients" className="grid__item" />
          <img src={img8} alt="Floating clients" className="grid__item" />
          <img src={img6} alt="Floating clients" className="grid__item" />
          <img src={img5} alt="Floating clients" className="grid__item" />
          <img src={img7} alt="Floating clients" className="grid__item" />
        </Grid>
      </>
    )
  }
}

export default PortfolioIndex
